import EnvUtility from "@/commons/utilities/EnvUtility";
import Navigator from "@/commons/utilities/Navigator";
class BreakPointUtility {
  static isMobile(rStateHeaders) {
    if (EnvUtility.isClientSide)
      return document.body.offsetWidth < BreakPointUtility.TABLET_MIN_WIDTH;
    else return Navigator.isMobile(rStateHeaders);
  }
  static isTablet(rStateHeaders) {
    if (EnvUtility.isClientSide)
      return (
        document.body.offsetWidth >= BreakPointUtility.TABLET_MIN_WIDTH &&
        document.body.offsetWidth < BreakPointUtility.TABLET_MIN_WIDTH
      );
    else return Navigator.isTablet(rStateHeaders);
  }
  static isTabletDown(rStateHeaders) {
    return (
      BreakPointUtility.isTablet(rStateHeaders) ||
      BreakPointUtility.isMobile(rStateHeaders)
    );
  }
  static isDesktop(rStateHeaders) {
    if (EnvUtility.isClientSide)
      return document.body.offsetWidth >= BreakPointUtility.DESKTOP_MIN_WIDTH;
    else return Navigator.isDesktop(rStateHeaders);
  }

  static log(rStateHeaders) {
    console.log("@@@---### isClientSide", EnvUtility.isClientSide);
    if (EnvUtility.isClientSide) {
      console.log("@@@---### offsetWidth", document.body.offsetWidth);
    } else {
      console.log("@@@---### isMobile", Navigator.isMobile(rStateHeaders));
      console.log("@@@---### isDesktop", Navigator.isDesktop(rStateHeaders));
    }
  }
}

BreakPointUtility.MOBILE_MIN_WIDTH = 320;
BreakPointUtility.TABLET_MIN_WIDTH = 768;
BreakPointUtility.DESKTOP_MIN_WIDTH = 1300;
BreakPointUtility.LG_DESKTOP_MIN_WIDTH = 1800;
BreakPointUtility.XL_DESKTOP_MIN_WIDTH = 2400;

export default BreakPointUtility;
