import usePQTranslations from "@/commons/hooks/usePQTranslations";

class ConvertUtility {
  static mToKm(distance) {
    if (!distance || typeof distance == "string")
      return { val: null, unit: null };
    return {
      val: Math.round((distance / 1000) * 10) / 10,
      unit: ConvertUtility.UNIT_MEASURE.KM,
    };
  }

  static mToM(distance) {
    if (!distance || typeof distance == "string")
      return { val: null, unit: null };
    distance = Math.round(distance / 50) * 50;
    return {
      val: distance !== 0 ? distance : 50,
      unit: ConvertUtility.UNIT_MEASURE.M,
    };
  }

  static mToMi(distance) {
    if (!distance || typeof distance == "string")
      return { val: null, unit: null };
    return {
      val: Math.round((distance / 1609) * 10) / 10,
      unit: ConvertUtility.UNIT_MEASURE.MI,
    };
  }

  static mToYd(distance) {
    if (!distance || typeof distance == "string")
      return { val: null, unit: null };
    const distConvert = Math.round((distance / 1.094) * 10) / 10;
    distance = Math.round(distConvert / 50) * 50;
    return {
      val: distance !== 0 ? distance : 50,
      unit: ConvertUtility.UNIT_MEASURE.YD,
    };
  }

  static distance(dist) {
    if (!dist) return { val: null, unit: null };
    if (typeof dist == "string") {
      dist = parseInt(dist);
      if (dist == NaN) return { val: null, unit: null };
    }
    const t = usePQTranslations();
    const unitMeasureDistance = t("unit_measure.distance");
    if (ConvertUtility.UNIT_MEASURE.KM == unitMeasureDistance) {
      if (Math.round(dist / 50) * 50 >= 1000) return ConvertUtility.mToKm(dist);
      else return ConvertUtility.mToM(dist);
    } else {
      const distConvert = Math.round((dist / 1609) * 10) / 10;
      if (distConvert >= 0.5) return ConvertUtility.mToMi(dist);
      else return ConvertUtility.mToYd(dist);
    }
  }
}

ConvertUtility.UNIT_MEASURE = {
  MI: "mi",
  YD: "yd",
  KM: "km",
  M: "m",
};

export default ConvertUtility;
