import React, { memo, useRef, useCallback, useEffect, useState } from "react";
import styles from "./DFP.module.scss";
import DOMInjectorUtility from "@/commons/utilities/DOMInjectorUtility";
import PqConsole from "@/commons/utilities/PqConsole";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const windowOk = typeof window !== "undefined";
const pubadsOk =
  windowOk &&
  typeof window.googletag !== "undefined" &&
  typeof window.googletag.pubads === "function";

const DFP = ({
  method,
  code,
  target,
  dimensions,
  targeting,
  sizeMapping: sizeMappingRules,
  setCollapseEmptyDiv,
  disableInitialLoad,
  renderAdvForOneTrust,
  maxRefreshAttempts,
  enabledYieldbird,
  enabledYieldlove,
  lazyLoadingYieldlove,
}) => {
  const divGptAdMethod = `div-gpt-ad-${method}`;
  const slotPath = `/${code}/${target}`;
  let refreshAttempts = 0;

  const [injectDFP, setInjectDFP] = useState(false);
  const [injectYB, setInjectYB] = useState(false);
  const [injectYL, setInjectYL] = useState(false);
  const prevRenderAdvForOneTrust = usePrevious(renderAdvForOneTrust);

  //--- load script DFP / YB ---
  const getStateRenderAdvForOneTrust = useCallback(() => {
    const onetrustConsent = renderAdvForOneTrust.onetrustConsent;
    const newIsUserActiveAds =
      renderAdvForOneTrust?.isUserSetPreferences &&
      renderAdvForOneTrust?.isUserActiveAds;
    const oldIsUserActiveAds =
      prevRenderAdvForOneTrust &&
      prevRenderAdvForOneTrust.isUserSetPreferences &&
      prevRenderAdvForOneTrust.isUserActiveAds;

    return { newIsUserActiveAds, oldIsUserActiveAds, onetrustConsent };
  }, [prevRenderAdvForOneTrust, renderAdvForOneTrust]);

  useEffect(() => {
    const { newIsUserActiveAds, onetrustConsent } =
      getStateRenderAdvForOneTrust();

    if (onetrustConsent == "TCF1" && !newIsUserActiveAds) return;

    injectScriptDFP().then((result) => {
      setInjectDFP(true);
      if (enabledYieldbird)
        injectScriptYieldbird().then((result) => {
          setInjectYB(true);
        });
      else if (enabledYieldlove) {
        injectScriptYieldlove().then((result) => {
          setInjectYL(true);
        });
      }
    });
  }, [
    renderAdvForOneTrust,
    prevRenderAdvForOneTrust,
    getStateRenderAdvForOneTrust,
  ]);

  useEffect(() => {
    if (prevRenderAdvForOneTrust && !enabledYieldbird && !enabledYieldlove) {
      const { newIsUserActiveAds, oldIsUserActiveAds } =
        getStateRenderAdvForOneTrust();

      if (onetrustConsent == "TCF1" && !newIsUserActiveAds) return;

      if (oldIsUserActiveAds !== newIsUserActiveAds) {
        if (pubadsOk) {
          window.googletag.cmd.push(() => {
            //init isADVPersonalizzed
            window.googletag
              .pubads()
              .setRequestNonPersonalizedAds(newIsUserActiveAds ? 0 : 1);
            const listSlots = window.googletag.pubads().getSlots();
            const currentSlot = listSlots.find(
              (slot) => slot.getAdUnitPath() == slotPath,
            );
            if (currentSlot) {
              window.googletag.pubads().refresh([currentSlot]);
            }
          });
        }
      }
    }
  }, [
    renderAdvForOneTrust,
    prevRenderAdvForOneTrust,
    getStateRenderAdvForOneTrust,
  ]);

  const handleSlotRenderEnded = useCallback((e) => {
    if (maxRefreshAttempts && e.slot.getSlotElementId() === divGptAdMethod) {
      if (e.isEmpty && refreshAttempts <= maxRefreshAttempts) {
        refreshAttempts++;
        if (pubadsOk) {
          window.googletag.cmd.push((_) => {
            window.googletag.pubads().refresh([e.slot]);
          });
        }
      }
    }
  });

  useEffect(() => {
    if (code == null || target == null || method == null) return;

    if (enabledYieldbird) {
      //--- confs yieldBird ---
      if (!injectDFP || !injectYB) return;
      loadYBConfs();
    } else if (enabledYieldlove) {
      //--- confs yieldLove ---
      if (!injectDFP || !injectYL) return;
      loadYLConfs();
    } else {
      //--- confs DFP ---
      if (!injectDFP) return;
      loadDFPConfs();
    }
  }, [injectDFP, injectYB, injectYL]);

  const loadYBConfs = useCallback(() => {
    window.Yieldbird.cmd.push(() => {
      window.Yieldbird.display(divGptAdMethod);
    });
  });

  const loadYLConfs = useCallback(() => {
    if (pubadsOk) {
      window.yieldlove_cmd.push(() => {
        window.googletag.cmd.push(() => {
          window.googletag.pubads().disableInitialLoad();
          window.googletag.enableServices();
        });

        loadDFPConfs(false);
        if (lazyLoadingYieldlove) {
          window.YLHH.utils.lazyLoad(divGptAdMethod, () => {
            window.YLHH.bidder.startAuction(slotPath, undefined);
          });
        }
      });
    }
  });

  const loadDFPConfs = useCallback((originalConfsDFP = true) => {
    //init slot
    if (pubadsOk) {
      window.googletag.cmd.push(() => {
        const isUserActiveAds =
          renderAdvForOneTrust &&
          renderAdvForOneTrust.isUserSetPreferences &&
          renderAdvForOneTrust.isUserActiveAds;

        if (isUserActiveAds)
          window.googletag.pubads().setRequestNonPersonalizedAds(1);

        const slot = window.googletag.defineSlot(
          slotPath,
          dimensions,
          divGptAdMethod,
        );

        if (slot == null) {
          PqConsole.warn(`DFP: slot not defined. Could be a duplicate case.`);
          return null;
        }

        targeting.forEach((t) => {
          slot.setTargeting(t[0], t[1]);
        });

        let sizeMapping = [];
        if (sizeMappingRules.length > 0) {
          sizeMapping = window.googletag.sizeMapping();
          sizeMappingRules.forEach((size) => {
            sizeMapping.addSize(size[0], size[1]);
          });
          sizeMapping = sizeMapping.build();
        }

        if (originalConfsDFP)
          window.googletag
            .pubads()
            .addEventListener("slotRenderEnded", handleSlotRenderEnded);

        slot
          .defineSizeMapping(sizeMapping)
          .setCollapseEmptyDiv(setCollapseEmptyDiv, setCollapseEmptyDiv)
          .addService(window.googletag.pubads());

        window.googletag.slots.push(slot);

        if (disableInitialLoad) window.googletag.pubads().disableInitialLoad();

        window.googletag.enableServices();
      });

      //display slot
      window.googletag.cmd.push(() => {
        window.googletag.display(divGptAdMethod);
      });
    }
  });

  return (
    <div
      data-type={
        enabledYieldbird
          ? "DFP_WITH_YB"
          : enabledYieldlove
          ? "DFP_WITH_YL"
          : "DFP"
      }
      data-slot={target}
      className={styles.dfp}
      key={`DFP-${method}`}
      id={divGptAdMethod}
    ></div>
  );
};

const injectScriptDFP = () =>
  new Promise((resolve, reject) => {
    const { pqConfs } = useSelectorPQGlobalThis();

    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
    window.googletag.slots = window.googletag.slots || [];

    DOMInjectorUtility.script(pqConfs.ads.dfp.settings.dfpScript, "DFP")
      .then((result) => resolve(true))
      .catch((error) => reject(error));
  });

const injectScriptYieldbird = () =>
  new Promise((resolve, reject) => {
    const { pqConfs } = useSelectorPQGlobalThis();
    window.Yieldbird = window.Yieldbird || {};
    window.Yieldbird.cmd = window.Yieldbird.cmd || [];

    DOMInjectorUtility.script(
      pqConfs.ads.dfp.settings.yieldBirdScript,
      "YIELDBIRD",
    )
      .then((result) => resolve(true))
      .catch((error) => reject(error));
  });

const injectScriptYieldlove = () =>
  new Promise((resolve, reject) => {
    const { pqConfs } = useSelectorPQGlobalThis();
    //window.yieldlove_prevent_autostart = true;
    window.yieldlove_cmd = window.yieldlove_cmd || [];

    DOMInjectorUtility.script(
      pqConfs.ads.dfp.settings.yieldLoveScript,
      "YIELDLOVE",
    )
      .then((result) => {
        DOMInjectorUtility.script(
          pqConfs.ads.dfp.settings.doubleClickScript,
          "DOUBLECLICK",
        )
          .then((result) => resolve(true))
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });

DFP.defaultProps = {
  code: null,
  target: null,
  method: null,
  dimensions: [],
  targeting: [],
  sizeMapping: [],
  setCollapseEmptyDiv: true,
  disableInitialLoad: false,
  renderAdvForOneTrust: {},
  lazyLoadingYieldlove: false,
};

export default memo(DFP, (prev, next) => prev.method == next.method);
export { injectScriptDFP };
