import React, { useCallback, useMemo, useState, ReactNode } from "react";
import style from "./Card.module.scss";

import Heading from "@/commons/ui/components/heading/Heading";
import PQImg from "@/commons/ui/components/pq-img/PQImg";
import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";

interface Props {
  title: string;
  headingLevel?: number;
  collapsable?: boolean;
  collapsed?: boolean;
  additionalClasses?: any;
  actions?: any;
  onExpand?: () => {};
  onCollapse?: () => {};
  logo?: any;
  logoComponent?: any;
  children?: ReactNode;
  onToggleCollapse?: any;
}

const Card = ({
  additionalClasses = {} as any,
  title,
  headingLevel = 2,
  logo = null,
  logoComponent = null,
  actions = [] as any,
  children,
  collapsable = false,
  collapsed = false,
  onToggleCollapse,
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const getClasses = useMemo(() => {
    let classesCard = {
      card: [style.card],
      header: [style.cardHeader],
      actions: [style.cardActions],
      tools: [style.cardTools],
      body: [style.cardBody],
    };

    if (collapsable || isCollapsed) {
      classesCard.card.push(style.cardCollapsable);
    }
    if (isCollapsed) {
      classesCard.card.push(style.cardCollapsed);
    }
    if (actions.length) {
      classesCard.card.push(style.cardWithActions);
    }

    if (additionalClasses.card) {
      classesCard.card = [...classesCard.card, ...additionalClasses.card];
    }
    if (additionalClasses.header) {
      classesCard.header = [...classesCard.header, ...additionalClasses.header];
    }
    if (additionalClasses.actions) {
      classesCard.actions = [
        ...classesCard.actions,
        ...additionalClasses.actions,
      ];
    }
    if (additionalClasses.tools) {
      classesCard.tools = [...classesCard.tools, ...additionalClasses.tools];
    }
    if (additionalClasses.body) {
      classesCard.body = [...classesCard.body, ...additionalClasses.body];
    }

    return classesCard;
  }, [actions.length, additionalClasses.actions, additionalClasses.body, additionalClasses.card, additionalClasses.header, additionalClasses.tools, collapsable, isCollapsed]);

  const actionsItems = useMemo(() => {
    const actionsItems = actions.map((action, index) => {
      const { content, onClick } = action;

      return (
        <div
          key={`card-action-${index}`}
          className={style.action}
          onClick={() => onClick(action)}
        >
          {typeof content === "string" && (
            <div
              className={style.actionContent}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
          {typeof content === "object" && (
            <div className={style.actionContent}>{content}</div>
          )}
        </div>
      );
    });

    if (collapsable) {
      const icon = isCollapsed ? "chevron-down" : "chevron-up";
      actionsItems.push(
        <div key={`card-action-collapsed-toggler`} className={style.action}>
          <PQIcon icon={icon} />
        </div>,
      );
    }
    return actionsItems;
  }, [actions, collapsable, isCollapsed]);

  const handleHeaderClick = useCallback(() => {
    if (collapsable) {
      setIsCollapsed(!isCollapsed);
      onToggleCollapse(!isCollapsed);
    }
  }, [collapsable, isCollapsed, onToggleCollapse]);

  const header = useMemo(() => {
    if (title == null && actions.length == 0) return;

    return (
      <div
        className={getClasses.header.join(" ")}
        onClick={() => handleHeaderClick()}
      >
        {title && (
          <Heading level={headingLevel} className={style.title}>
            {title}
          </Heading>
        )}
        {actionsItems.length > 0 && (
          <div className={getClasses.actions.join(" ")}>{actionsItems}</div>
        )}
        {(logo || logoComponent) && (
          <div className={style.logo}>
            {logo && <PQImg imgModel={logo} altText={title} />}
            {logoComponent}
          </div>
        )}
      </div>
    );
  }, [
    actions.length,
    actionsItems,
    getClasses.actions,
    getClasses.header,
    handleHeaderClick,
    headingLevel,
    logo,
    logoComponent,
    title,
  ]);

  return (
    <div className={getClasses.card.join(" ")}>
      {header}
      <div className={getClasses.body.join(" ")}>
        <div className={style.cardBodySeparator} />
        {children}
      </div>
    </div>
  );
};

// Card.defaultProps = {
// 	title: null,
// 	headingLevel: 2,
// 	collapsable: false,
// 	collapsed: false,
// 	additionalClasses: {},
// 	actions: [],
// 	tools: [],
// 	onExpand: () => {},
// 	onCollapse: () => {},
// 	logo: null,
// 	logoComponent: null,
// };

export default Card;

// import styles from './Card.scss';

// import Image from '../Image';
// import Heading from '../Heading';

// class Card extends PureComponent {
// 	constructor(props) {
// 		super();

// 		this.state = {
// 			collapsed: props.collapsed,
// 		};
// 	}

// 	_getClasses() {
// 		const { classes: additionalClasses, actions, collapsable } = this.props;
// 		const { collapsed } = this.state;

// 		let classes = {
// 			card: [styles.card],
// 			header: [styles.cardHeader],
// 			actions: [styles.cardActions],
// 			tools: [styles.cardTools],
// 			body: [styles.cardBody],
// 		};

// 		if (collapsable) {
// 			classes.card.push(styles.cardCollapsable);
// 		}
// 		if (collapsed) {
// 			classes.card.push(styles.cardCollapsed);
// 		}
// 		if (actions.length) {
// 			classes.card.push(styles.cardWithActions);
// 		}
// 		if (additionalClasses.card) {
// 			classes.card = [...classes.card, ...additionalClasses.card];
// 		}
// 		if (additionalClasses.header) {
// 			classes.header = [...classes.header, ...additionalClasses.header];
// 		}
// 		if (additionalClasses.actions) {
// 			classes.actions = [...classes.actions, ...additionalClasses.actions];
// 		}
// 		if (additionalClasses.tools) {
// 			classes.tools = [...classes.tools, ...additionalClasses.tools];
// 		}
// 		if (additionalClasses.body) {
// 			classes.body = [...classes.body, ...additionalClasses.body];
// 		}

// 		return classes;
// 	}

// 	toggleCollasedState() {
// 		this.setState(
// 			(prevState) => ({
// 				collapsed: !prevState.collapsed,
// 			}),
// 			() => {
// 				const { collapsed } = this.state;
// 				const { onCollapse, onExpand } = this.props;

// 				collapsed ? onCollapse() : onExpand();
// 			}
// 		);
// 	}

// 	handleHeaderClick() {
// 		const { collapsable } = this.props;

// 		if (collapsable) {
// 			this.toggleCollasedState();
// 		}
// 	}

// 	render() {
// 		const { title, headingLevel, logo, logoComponent, actions, children, collapsable } = this.props;

// 		const { collapsed } = this.state;

// 		const classes = this._getClasses();

// 		const actionsItems = actions.map((action, index) => {
// 			const { content, onClick } = action;

// 			return (
// 				<div
// 					key={`card-action-${index}`}
// 					className={styles.action}
// 					onClick={() => onClick(action)}>
// 					{typeof content === 'string' && (
// 						<div className={styles.actionContent} dangerouslySetInnerHTML={{ __html: content }} />
// 					)}
// 					{typeof content === 'object' && <div className={styles.actionContent}>{content}</div>}
// 				</div>
// 			);
// 		});

// 		if (collapsable) {
// 			actionsItems.push(
// 				<div key={`card-action-collapsed-toggler`} className={styles.action}>
// 					<fontAwesomeIcon icon={collapsed ? faChevronDown : faChevronUp} fixedWidth />
// 				</div>
// 			);
// 		}

// 		const header = (title != null || actions.length > 0) && (
// 			<div className={classes.header.join(' ')} onClick={() => this.handleHeaderClick()}>
// 				{title != null && (
// 					<Heading level={headingLevel} className={styles.title}>
// 						{title}
// 					</Heading>
// 				)}
// 				{actionsItems.length > 0 && <div className={styles.cardActions}>{actionsItems}</div>}
// 				{(logo || logoComponent) && (
// 					<div className={styles.logo}>
// 						{logo && <Image src={logo} alt={title} />}
// 						{logoComponent}
// 					</div>
// 				)}
// 			</div>
// 		);

// 		return (
// 			<div className={classes.card.join(' ')}>
// 				{header}
// 				<div className={classes.body.join(' ')}>
// 					<div className={styles.cardBodySeparator} />
// 					{children}
// 				</div>
// 			</div>
// 		);
// 	}
// }

// Card.defaultProps = {
// 	title: null,
// 	headingLevel: 2,
// 	collapsable: false,
// 	collapsed: false,
// 	classes: {},
// 	actions: [],
// 	tools: [],
// 	onExpand: () => {},
// 	onCollapse: () => {},
// 	logo: null,
// 	logoComponent: null,
// };

// Card.propTypes = {
// 	title: PropTypes.node,
// };

// export default Card;
