import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";

const BreadcrumbListJsonLD = (breadcrumbList = []) => {
  const jsonLD = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://" + pqConfs.frontendDomain,
      },
      ...breadcrumbList.map((item, index) => {
        const { pqConfs } = useSelectorPQGlobalThis();

        let breadcrumbListItem = {
          "@type": "ListItem",
          position: index + 2,
          name: item.pathSection,
        };

        if (item.url && item.url.length > 0) {
          breadcrumbListItem["item"] =
            "https://" + pqConfs.frontendDomain + item.url;
        }

        return breadcrumbListItem;
      }),
    ],
  };
  return JSON.stringify(jsonLD);
};

export default BreadcrumbListJsonLD;
