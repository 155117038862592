import React, { memo, useCallback, useEffect } from "react";
import styles from "./AdSense.module.scss";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import Navigator from "@/commons/utilities/Navigator";
import BreakPointUtility from "@/commons/utilities/BreakPointUtility";
import DOMInjectorUtility from "@/commons/utilities/DOMInjectorUtility";
import PqConsole from "@/commons/utilities/PqConsole";
import OneTrustUtils from "@/commons/tracking/OneTrustUtils";
import { useSelector } from "react-redux";

const AdSense = ({ sizes, slotID }) => {
  const { ccontext } = useSelector((state) => state);
  const { rStateHeaders } = ccontext;

  const { pqConfs } = useSelectorPQGlobalThis();

  const injectGoogleAdsense = useCallback(() => {
    return new Promise((resolve, reject) => {
      DOMInjectorUtility.script(
        pqConfs.ads.adsense.settings.script,
        "GOOGLE_ADSENSE",
      )
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  }, [pqConfs]);

  useEffect(() => {
    injectGoogleAdsense()
      .then((result) => {
        if (result) {
          (adsbygoogle = window.adsbygoogle || []).push({
            overlays: {
              bottom: true,
            },
          });

          let isUserActiveAds = OneTrustUtils.getPreferencesUser(
            OneTrustUtils.KEY.ADS,
          );
          (adsbygoogle = window.adsbygoogle || []).requestNonPersonalizedAds =
            isUserActiveAds ? 0 : 1;

          console.log("@@@#-- AdSense istanzied");
        } else {
          PqConsole.warning("AdSense not istanzied");
        }
      })
      .catch((error) => {
        PqConsole.warning("AdSense not istanzied", error);
      });
  }, [injectGoogleAdsense]);

  const getSizes = () => {
    const targetSizes = { ...AdSense.defaultSizes.fixed };

    /* Fixed sizes wins over breakpoints */
    if (sizes.fixed) return { ...targetSizes, ...sizes.fixed };
    if (
      sizes.mobile &&
      BreakPointUtility.isMobile(rStateHeaders) &&
      Navigator.isMobile(rStateHeaders)
    )
      return { ...targetSizes, ...sizes.mobile };
    if (
      sizes.tablet &&
      BreakPointUtility.isTablet(rStateHeaders) &&
      Navigator.isTablet(rStateHeaders)
    )
      return { ...targetSizes, ...sizes.tablet };
    if (
      sizes.desktop &&
      BreakPointUtility.isDesktop(rStateHeaders) &&
      Navigator.isDesktop(rStateHeaders)
    )
      return { ...targetSizes, ...sizes.desktop };

    return targetSizes;
  };

  const renderAdSense = () => {
    const client = pqConfs.ads.adsense.settings.client;

    const sizes = getSizes();

    const style = {
      display: "inline-block",
      width: sizes.width,
      height: sizes.height,
    };

    return (
      <div className={styles.adSense}>
        <ins
          className="adsbygoogle"
          data-ad-client={client}
          data-ad-slot={slotID}
          style={style}
        />
      </div>
    );
  };

  return renderAdSense();
};

export default memo(AdSense);

AdSense.defaultSizes = {
  fixed: {
    width: "inherit",
    height: "inherit",
  },
};
