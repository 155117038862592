import getDistance from "geolib/es/getDistance";
import PqConsole from "./PqConsole";

class DistanceUtility {
  static distanceUnitMeasure(distance, um) {
    if (!distance) return null;

    if (um == DistanceUtility.UNIT.KM) {
      distance = (distance / 1000).toFixed(1);
    } else if (um == DistanceUtility.UNIT.MI) {
      distance = (distance / 1609.344).toFixed(1);
    }

    return `${distance} ${um}`;
  }

  static getDistanceAB_m(a, b) {
    if (a?.latitude && b?.latitude && a?.longitude && b?.longitude) {
      try {
        const coordinateA = { latitude: a.latitude, longitude: a.longitude };
        const coordinateB = { latitude: b.latitude, longitude: b.longitude };
        const distance = getDistance(coordinateA, coordinateB);
        return distance;
      } catch (error) {
        PqConsole.error("getDistanceAB_m", error);
      }
    }
    return null;
  }
}

DistanceUtility.UNIT = {
  KM: "km",
  MI: "mi",
};

export default DistanceUtility;
