import React, { useRef, useEffect, useState } from "react";
import styles from "./Criteo.module.scss";
import { injectScriptDFP } from "./DFP";

import DOMInjectorUtility from "@/commons/utilities/DOMInjectorUtility";
import PqConsole from "@/commons/utilities/PqConsole";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const windowOk = typeof window !== "undefined";
const pubadsOk =
  windowOk &&
  typeof window.googletag !== "undefined" &&
  typeof window.googletag.pubads === "function";

const Criteo = ({
  networkid,
  method,
  publishersubid,
  itemRanges,
  code,
  target,
  dimensions,
  targeting,
  sizeMapping: sizeMappingRules,
  setCollapseEmptyDiv,
  renderAdvForOneTrust,
}) => {
  const [inject, setInject] = useState(false);
  const [injectDFP, setInjectDFP] = useState(false);
  const prevRenderAdvForOneTrust = usePrevious(renderAdvForOneTrust);
  //const [isUserActiveAds, setIsUserActiveAds] = useState(false);

  useEffect(() => {
    window.Criteo = window.Criteo || {};
    window.Criteo.events = window.Criteo.events || [];
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];

    Criteo.injectScriptCriteo().then((result) => {
      setInject(true);
    });
    injectScriptDFP().then((result) => {
      setInjectDFP(true);
    });
  }, []);

  useEffect(() => {
    if (prevRenderAdvForOneTrust) {
      const newIsUserActiveAds =
        renderAdvForOneTrust &&
        renderAdvForOneTrust.isUserSetPreferences &&
        renderAdvForOneTrust.isUserActiveAds;

      const oldIsUserActiveAds =
        prevRenderAdvForOneTrust &&
        prevRenderAdvForOneTrust.isUserSetPreferences &&
        prevRenderAdvForOneTrust.isUserActiveAds;

      if (oldIsUserActiveAds !== newIsUserActiveAds) {
        if (pubadsOk) {
          window.googletag.cmd.push(() => {
            //init isADVPersonalizzed
            window.googletag
              .pubads()
              .setRequestNonPersonalizedAds(newIsUserActiveAds ? 0 : 1);
            const listSlots = window.googletag.pubads().getSlots();
            const currentSlot = listSlots.find(
              (slot) => slot.getAdUnitPath() == `/${code}/${target}`,
            );
            if (currentSlot) {
              window.googletag.pubads().refresh([currentSlot]);
            }
          });
        }
      }
    }
  }, [renderAdvForOneTrust]);

  useEffect(() => {
    if (inject && injectDFP) {
      if (code == null || target == null || method == null) return;

      const sizes = [];
      dimensions.forEach((dim) => {
        if (dim != "fluid") {
          sizes.push(`${dim[0]}x${dim[1]}`);
        }
      });

      const adUnits = {
        networkId: networkid,
        placements: [
          {
            slotid: `div-gpt-tag-${method}`,
            sizes: sizes,
            publishersubid: publishersubid,
          },
        ],
      };

      window.Criteo.events.push(() => {
        window.Criteo.SetLineItemRanges(itemRanges || Criteo.itemRanges);
        window.Criteo.RequestBids(adUnits, launchAdServer, 1500);
      });

      if (pubadsOk) {
        window.googletag.cmd.push(() => {
          const isUserActiveAds =
            renderAdvForOneTrust &&
            renderAdvForOneTrust.isUserSetPreferences &&
            renderAdvForOneTrust.isUserActiveAds;

          if (!isUserActiveAds)
            window.googletag.pubads().setRequestNonPersonalizedAds(1);

          const slot = window.googletag.defineSlot(
            `/${code}/${target}`,
            dimensions,
            `div-gpt-tag-${method}`,
          );

          if (slot == null) {
            PqConsole.warn(`DFP: slot not defined. Could be a duplicate case.`);
            return null;
          }
          targeting.forEach((t) => {
            slot.setTargeting(t[0], t[1]);
          });

          let sizeMapping = [];
          if (sizeMappingRules.length > 0) {
            sizeMapping = window.googletag.sizeMapping();
            sizeMappingRules.forEach((size) => {
              sizeMapping.addSize(size[0], size[1]);
            });
            sizeMapping = sizeMapping.build();
          }
          if (pubadsOk) {
            slot
              .defineSizeMapping(sizeMapping)
              .setCollapseEmptyDiv(setCollapseEmptyDiv, setCollapseEmptyDiv)
              .addService(window.googletag.pubads());

            window.googletag.slots.push(slot);

            window.googletag.pubads().enableSingleRequest();
            window.googletag.pubads().disableInitialLoad();
            window.googletag.enableServices();
          }
        });
      }
    }
  }, [inject, injectDFP]);

  const launchAdServer = (bids) => {
    if (pubadsOk) {
      window.googletag.cmd.push(() => {
        const currentSlot = googletag
          .pubads()
          .getSlots()
          .find((slot) => slot.getAdUnitPath() == `/${code}/${target}`);
        window.Criteo.SetDFPKeyValueTargeting();
        window.googletag.pubads().refresh([currentSlot]);
      });
    }
  };

  return (
    inject &&
    injectDFP && (
      <div
        data-type={"CRITEO"}
        className={styles.criteo}
        key={`div-gpt-tag-${method}`}
        id={`div-gpt-tag-${method}`}
      ></div>
    )
  );
};

Criteo.itemRanges =
  "0..4.5:0.01;4.51..27.01:0.05;27...02..68.82:0.10;68...83..100.83:1.0";

Criteo.injectScriptCriteo = () => {
  return new Promise((resolve, reject) => {
    const { pqConfs } = useSelectorPQGlobalThis();
    DOMInjectorUtility.script(pqConfs.ads.criteo.settings.script, "CRITEO")
      .then((result) => resolve(true))
      .catch((error) => reject(error));
  });
};

Criteo.defaultProps = {
  code: null,
  target: null,
  method: null,
  dimensions: [],
  targeting: [],
  sizeMapping: [],
  setCollapseEmptyDiv: true,
  disableInitialLoad: false,
  renderAdvForOneTrust: {},
};

export default Criteo;
