class DOMInjectorUtility {
  static script = (
    scriptUrl,
    scriptDataType,
    type = "text/javascript",
    async = true,
  ) => {
    return new Promise((resolve, reject) => {
      try {
        const refScript = document.querySelector(
          `script[data-type="${scriptDataType}"]`,
        );
        if (!refScript) {
          const script = document.createElement("script");
          script.dataset.type = scriptDataType;
          script.type = type;
          script.async = async;
          script.onload = () => resolve(true);
          script.onerror = (error) => reject(error);
          script.src = scriptUrl;
          document.getElementsByTagName("head")[0].appendChild(script);
        } else {
          resolve(true);
        }
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default DOMInjectorUtility;
