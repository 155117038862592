const AdsProviderConstants = {
  DFP: "dfp",
  ADSENSE: "adsense",
  CRITEO: "criteo",
  YIELDLOVE: "yieldlove",
  DOUBLECLICK: "dc",
};

const AdsSlotsContants = {
  MAST_PG: "mast_pg",
  MASTHEAD_WEB_DESKTOP: "mastheadWebDesktop",
  MASTHEAD_WEB_TABLET: "mastheadWebTablet",
  MASTHEAD_WEB_MOBILE: "mastheadWebMobile",
  SERP_FOOTER: "serpFooter",
  SERP_SIDEBAR: "serpSidebar",
  SERP_RTL_1: "serpRTL1",
  SERP_RTL_2: "serpRTL2",
  SERP_RTL_3: "serpRTL3",
  LEAFLETS_CAROUSEL_1: "leafletsCarousel1",
  LEAFLETS_CAROUSEL_2: "leafletsCarousel2",
  LEAFLETS_CAROUSEL_3: "leafletsCarousel3",
  LEAFLETS_CAROUSEL_4: "leafletsCarousel4",
  LEAFLETS_CAROUSEL_5: "leafletsCarousel5",
  LEAFLETS_CAROUSEL_6: "leafletsCarousel6",
  RETAILERS_CAROUSEL_1: "retailersCarousel1",
  RETAILERS_CAROUSEL_2: "retailersCarousel2",
  OFFERS_1: "offers_1",
  OFFERS_2: "offers_2",
  OFFERS_3: "offers_3",
  OFFERS_4: "offers_4",
  LEAFLET_VIEWER_INTERNAL_1: "leaflet_viewer_internal_1",
  LEAFLET_VIEWER_INTERNAL_2: "leaflet_viewer_internal_2",
  LEAFLET_VIEWER_INTERNAL_3: "leaflet_viewer_internal_3",
  LEAFLET_VIEWER_BOTTOM: "leaflet_viewer_bottom",
  LEAFLET_VIEWER_SIDE: "leaflet_viewer_side",
  LEAFLET_INTERNAL_CATEGORY: "leaflet_internal_category",
};

export { AdsProviderConstants, AdsSlotsContants };
