import React from "react";
import styles from "./TwoColumnsSide.module.scss";

const TwoColumnsSide = ({ children, left = false }) => (
  <div
    className={`${styles.twoColumnsSide} ${left && styles.twoColumnsSideLeft}`}
  >
    {children}
  </div>
);

export default TwoColumnsSide;
